
import { defineComponent } from "vue";
import BaseHeader from "@/components/BaseHeader2.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import TextButton from "@/components/common/TextButton.vue";
import SelectBox from "@/components/common/SelectBox.vue";
import { useStore } from "vuex";
import { categories } from "@/components/talkroom/constants";

export default defineComponent({
  name: "TalkroomUpload",
  components: {
    SelectBox,
    BaseHeader,
    TextButton,
    BaseTextarea,
  },
  data() {
    const store = useStore();
    return {
      store,
      page: 1,
      category: "",
      detail: "",
      options: this.$_.map(categories, (v) => {
        return { display: v, value: v };
      }),
      isLoading: false,
    };
  },
  computed: {
    btnColor() {
      if (this.isValid) {
        return "var(--blue1-color)";
      }
      return "var(--grey1-color)";
    },
    isValid() {
      if (this.category && this.detail) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submit() {
      if (!this.isValid) {
        return;
      }
      this.isLoading = true;
      this.$axios
        .post("/board", {
          category: this.category,
          detail: this.detail,
        })
        .then(() => {
          this.$pushGAEvent("talkroom_write_complete", {
            talkroom_category: this.category,
          });
          this.isLoading = false;
          this.store.dispatch("talkroom/updateSelectedTabName", this.category);
          this.$router.push({
            path: "/talkroom",
            query: { category: this.category },
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  beforeMount() {
    this.$flutter.callHandler("hideGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
  mounted() {
    const category = this.store.getters["talkroom/getSelectedTabName"];
    if (category) {
      this.category = category;
    }
    this.$pushGAEvent("talkroom_write_try", {
      talkroom_category: category,
    });
  },
});
