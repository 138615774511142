export const categories = [
  "질문",
  "사건사고",
  "장비팔아요",
  "장비구해요",
  "작업일지",
  "로케이션",
  "홍보",
  "스터디",
  "정보공유",
];
