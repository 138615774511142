
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    fontSize: {
      type: String,
      default: "14px",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    input(e: InputEvent) {
      this.$emit("update:modelValue", (e.target as HTMLInputElement).value);
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "58d35f1e": (_ctx.width),
  "b06cea62": (_ctx.height),
  "2603d098": (_ctx.fontSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__