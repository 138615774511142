import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5766654"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    placeholder: _ctx.placeholder,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
    value: _ctx.modelValue
  }, null, 40, _hoisted_1))
}