
import { defineComponent } from "vue";
import BottomSheet from "@/components/BottomSheet.vue";
import ChevronDown from "icons/ChevronDown.vue";
import ChevronUp from "icons/ChevronUp.vue";

interface Option {
  display: string;
  value: string | number;
}

type Options = Array<Option>;

export default defineComponent({
  name: "SelectBox",
  components: {
    ChevronUp,
    ChevronDown,
    BottomSheet,
  },
  data() {
    return {
      showBS: false,
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "선택해 주세요.",
    },
    modelValue: {
      type: String,
      default: "",
    },
    options: {
      required: true,
      type: Object as () => Options,
    },
  },
  computed: {
    display() {
      if (this.modelValue) {
        return this.modelValue;
      }
      return this.placeholder;
    },
  },
  methods: {
    select(option: Option): void {
      this.$emit("update:modelValue", option.value);
    },
  },
  emits: ["update:modelValue"],
});
